import React from "react";
import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  AppointmentForm,
  ContentLanguage,
} from "../components";
import { Row, Col } from "antd";

const Appointment = () => (
  <Layout>
    <SEO title="Appointment" description={"Request an Appointment"} />
    <BannerPage title={"Request Appointment"} />

    <ContentLanguage />
    <ContainerContent>
      <ContainerContentFixed>
        <Row>
          <Col md={{ span: 12, offset: 6 }} sm={24}>
            <AppointmentForm />
          </Col>
        </Row>
      </ContainerContentFixed>
    </ContainerContent>
    <ContainerCta
      title={"Need more information?"}
      secondaryLink={InternalLinkTypes.Contact}
      secondaryText={"Contact Our Team"}
    />
  </Layout>
);

export default Appointment;
